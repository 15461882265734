import * as React from "react"

import MenuPage from "../components/menu-page"
import Subscribe from "../components/subscribe"
import Footer from "../components/footer"
import SEO from "../components/seo"

const HostingPlatformPage = () => (
  <div>
    <SEO 
        title="Web Hosting Platform" 
        description="An ingenious design. A dependable website hosting environment. Extremely fast site loading rates. A 99.9% server uptime."
    />
    <div className="sub-section-wrap">
          <MenuPage/>
    </div>

    <div className="sub-section-wrap py-32">
      <div className="w-full mx-auto bg-white px-5 text-gray-600 mb-10">
          <div className="text-center mx-auto">
              <p className="section-title">Website Hosting <span className="block text-indigo-600 xl:inline">Platform</span></p>
              <p className="section-after-title">
              An ingenious design. A dependable website hosting environment. Extremely fast site loading rates. A 99.9% server uptime.
              </p>
          </div>
      </div>
    </div>

    <div className="py-12 server-bg">
        <div className="w-3/5 mx-auto px-5 bg-white rounded-md opacity-90 p-5 text-gray-600 mb-10">
          <div className="mx-auto">
              <p className="section-title">An Innovative Website Hosting Platform <span role="img" aria-label="emoji">✨ </span></p>
              <p className="text-sm">
                Establishing your own website hosting platform is a really difficult and lengthy process. 
                It is additionally very risky – you commit both money and time in one task. This is why 
                we set–up our very own secure and also well–tested website hosting platform.
                <br/><br/>
                Everything runs using a considerably improved version of SELinux (Security–Enhanced Linux), 
                that is definitely essentially, the most secure and safe, if not the most secure and 
                protected Operating System to be found. And then we have chosen a collection of 
                well–performing hardware components that will not break that simply. This is 
                what permits us to guarantee you a 99.9% uptime for all of your 
                sites.
                <br/><br/>
                Our website hosting platform will in addition grant you with an extremely fast connection 
                with the rest of the world. All of our web servers are housed in grade–A data centers 
                found in different parts of the world, and also in each one datacenter we have 
                developed a customized internal network. Designed with enterprise–grade 
                hardware from Juniper, the network enables a very fast connection to 
                everyone online. And it also permits us to assure a 99.9% network 
                uptime with all of our web hosting solutions in each of the 
                datacenters.
              </p>
          </div>
        </div>
    </div>

    <div className="py-12 bg-gray-50">
        <div className="w-3/5 mx-auto px-5 text-gray-600 mb-10">
          <div className="mx-auto">
              <p className="section-title">Web Control Panel <span role="img" aria-label="emoji">✨ </span></p>
              <p className="text-sm">
                We, at Sheephostingbay, even developed a new Web Control Panel. It is actually designed to work 
                in the cloud and to take full advantage of our website hosting platform. For this reason, it 
                functions speedier as compared to all the other Control Panels available. Plus, it allows 
                you to regulate everything from only one place, without the need for any other billing 
                or domain management panels.
                <br/><br/>
                You’ll find some some impressive tools inside our Web Control Panel. The File Manager, for example, 
                will allow you to upload files to your web site by merely dragging them to your web browser 
                screen. No additional software is needed and everything goes through on a protected 
                connection. With our Domain Names Manager, it will be easier to deal with many 
                different domain names at the same time without any problem. Together with 
                your Web Stats Manager, you can examine statistics for your personal 
                sites, which are gathered in real time, meaning that you don’t need 
                to do anything at all on your end.
                <br/><br/>
                And furthermore, you’ll find a great deal of free bonuses that can make your task as a webmaster very 
                simple. You want to have a unique site on the web? With the Online Site Installer, you are able to 
                launch a new website that uses a unique theme in mere 4 basic steps. You want to get a brand new 
                web app set up? Using the Applications Installer tool, it can be done with zero setup necessary. 
                To be able to additionally accelerate your site – we have put together a selection of Website 
                Accelerator Tools that are just the thing for the job. And that’s only a little fraction of 
                what you will find in our Web Control Panel.
              </p>
          </div>
        </div>
    </div>
    
    <Subscribe/>
    <Footer/>
  </div>
)

export default HostingPlatformPage
